export const menuLinks = [
  {
    name: "HOME",
    href: "/",
    isActive: (routeName) => useStartsWith(routeName, "index"),
  },
  {
    name: "PROFILE",
    href: "/profile",
    isActive: (routeName) => useStartsWith(routeName, "profile"),
  },
  {
    name: "GAME OPPORTUNITIES",
    href: "/game-opportunities",
    isActive: (routeName) => useStartsWith(routeName, "game-opportunities"),
  },
  {
    name: "RIOTERS",
    href: "/rioters",
    isActive: (routeName) => useStartsWith(routeName, "rioters"),
  },
];
